import {
  CloseButton,
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";

import { CloseIcon } from "~/components/ui/icons/icon";

export interface ModalProps extends React.PropsWithChildren {
  title: string;
  isOpen: boolean;
  preventClose?: boolean;
  setIsOpen: (isOpen: boolean) => void;
  afterLeave?: () => void | undefined;
}

export default function Modal({
  title,
  isOpen,
  children,
  setIsOpen,
  preventClose = false,
  afterLeave = undefined,
}: ModalProps) {
  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        if (!preventClose) {
          setIsOpen(false);
        }
        setTimeout(() => {
          afterLeave?.();
        }, 300);
      }}
      className="relative z-50"
    >
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-black/90 duration-300 ease-out data-[closed]:opacity-0 data-[closed]:duration-100 data-[closed]:ease-in"
      />

      <div className="fixed inset-0 flex items-center justify-center p-8">
        <DialogPanel
          transition
          className="w-full max-w-3xl overflow-hidden rounded-3xl border border-white/10 bg-white/10 backdrop-blur-lg duration-300 ease-out will-change-transform data-[closed]:scale-95 data-[closed]:opacity-0 data-[closed]:duration-100 data-[closed]:ease-in"
        >
          <DialogTitle
            as="h2"
            className="border-b border-white/10 px-24 py-6 text-center text-2xl font-semibold"
          >
            {title}
          </DialogTitle>

          {children}

          <CloseButton
            disabled={preventClose}
            className="absolute right-6 top-5 p-2 transition disabled:opacity-50"
          >
            <CloseIcon width="14" height="14" />
          </CloseButton>
        </DialogPanel>
      </div>
    </Dialog>
  );
}
